<template>
  <div class="object">
    <div class="object_box">
      <div class="object_top">
        <p>孵化对象</p>
        <p>INCUBATION OBJECT</p>
      </div>
      <div class="object_bouutm">
        <carousel-3d class="content" :width="450" :height="600">
          <slide class="content_inedx" :index="0">
            <img src="@/assets/imgs/qianhe/fhdx.png" alt="" />
            <p>南北干货</p>
          </slide>
          <slide class="content_inedx" :index="1">
            <img src="@/assets/imgs/qianhe/fhdx1.png" alt="" />
            <p>南北干货</p>
          </slide>
          <slide class="content_inedx" :index="2">
            <img src="@/assets/imgs/qianhe/fhdx2.png" alt="" />
            <p>南北干货</p>
          </slide>
          <slide class="content_inedx" :index="3">
            <img src="@/assets/imgs/qianhe/fhdx3.png" alt="" />
            <p>南北干货</p>
          </slide>
          <slide class="content_inedx" :index="4">
            <img src="@/assets/imgs/qianhe/fhdx4.png" alt="" />
            <p>南北干货</p>
          </slide>
      
        
        </carousel-3d>
      </div>
    </div>
  </div>
</template>

<script>
import { Carousel3d, Slide } from "vue-carousel-3d";
export default {
  components: {
    Carousel3d,
    Slide,
  },
  data() {
    return {};
  },
};
</script>
<style scoped lang="less">
.object {
  width: 100%;
  margin-top: 150px;
  .object_box {
    max-width: 1200px;
    margin: 0 auto;
    .object_top {
      text-align: center;
      p:nth-child(1) {
        font-size: 30px;
        font-weight: unset;
        color: #000000;
        line-height: 70px;
      }
      p:nth-child(2) {
        font-size: 19px;
        font-family: unset;
        font-weight: unset;
        color: #000000;
        margin-bottom: 80px;

      }
    }
    .object_bouutm {
      overflow: hidden;
      .content {
        height: 630px !important;
        .content_inedx {
          height: 650px !important;
          background: #fff;
          border: none;
          img{
            max-width:452px ;
               max-height: 570px;
          }
          p {
            font-size: 20px;
            font-family: Source Han Sans CN;
            font-weight: 600;
            color: #000000;
            text-align: center;
            margin-top: 35px;
          }
        }
      }
    }
  }
}
</style>
