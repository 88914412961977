<template>
  <div class="technology">
    <div class="technology_box">
      <div class="technology_top">
        <p>战略政策</p>
        <p>STRATEGIC POLICY</p>
      </div>
      <div class="technology_bottom">
        <div class="top">
          <div class="top_left">
            <img src="@/assets/imgs/qianhe/znzc.png" alt="" />
          </div>
          <div class="top_right">
            <p>国家政策</p>
            <p>
              习近平总书记对“十四五”规划编制工作作出重要指示，强调编制和实施国民经济和社会发展五年的规划，是我们党治国理政的重要方式。五年规划编制涉及经济和社会发展方方面面，同人民群众生产生活息息相关，要开门问策、集思广益，齐心协力把“十四五”规划编制好。
            </p>
          </div>
        </div>

        <div class="bottom">
          <div class="bottom_left">
            <img src="@/assets/imgs/qianhe/zy.png" alt="" />
          </div>
          <div class="bottom_right">
            <p>昆仑虚战略</p>
            <p>
            四川昆仑虚科技发展有限公司响应习近平总书记的实现技术与产业相结合的计划方针而推出的“仟禾计划”布局乡村市场，为地方孵化一千家特色品牌（千县千品），让更多的特色产品走出去，带动乡村经济发展，助力乡村振兴！
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped lang="less">
.technology {
  width: 100%;
  margin-top: 150px;

  .technology_box {
    max-width: 1200px;
    margin: 0 auto;
    .technology_top {
      text-align: center;
      p:nth-child(1) {
        font-size: 30px;
        font-weight: unset;
        color: #000000;
        line-height: 70px;
      }
      p:nth-child(2) {
        font-size: 19px;
        font-family: unset;
        font-weight: unset;
        color: #000000;
        margin-bottom: 60px;
      }
    }
    .technology_bottom {
      max-width: 1100px;
      margin: 0 auto;
      .top {
        margin-bottom: 80px;
        overflow: hidden;
        .top_left {
          float: left;
        }
        .top_right {
          float: right;
          p:nth-child(1) {
            font-size: 30px;
            font-weight: unset;
            color: #c3042c;
            margin: 30px 0 25px 0;
          }
          p:nth-child(2) {
            max-width: 400px;
            font-size: 18px;
            font-weight: unset;
            color: #000000;
            line-height: 36px;
          }
        }
      }
      .bottom {
        overflow: hidden;
        .bottom_left {
          float: right;
        }
        .bottom_right {
          float: left;
          p:nth-child(1) {
            font-size: 30px;
            font-weight: unset;
            color: #c3042c;
            margin: 30px 0 25px 0;
          }
          p:nth-child(2) {
            max-width: 400px;
            font-size: 18px;
            font-weight: unset;
            color: #000000;
            line-height: 36px;
          }
        }
      }
    }
  }
}
</style>
