<template>
  <div class="garousel">
    <div v-for="(item, index) in content" :key="index" class="garousel">
      <a class="Carousel_img"><img :src="item.img" alt="" /></a>
      <div class="position">
        <div class="slit">
          <p>仟禾计划</p>
          <p class="nth">千县千品-助力乡村振兴</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      content: [
        {
          img: require("@/assets/imgs/qianhe/whfhbanner.png"),
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.garousel,
.Carousel_img,
.Carousel_img > img {
  min-width: 1225px;
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  min-height: 755px;
}

.Carousel_img {
  display: inline-block;
  position: relative;
}

.position {
  position: absolute;
  top: 260px;
  left: 0px;
  width: 100%;

  .slit {
    margin: 0 auto;
    max-width: 660px;
    height: 300px;
    p:nth-child(1) {
      font-size: 80px;
      font-family: "qingk";
      letter-spacing: 2px;
      font-family: "STHeitiSC";
      text-align: center;
      color: #ffffff;
    }
    p:nth-child(2) {
      margin-top: 100px;
      letter-spacing: 1px;
      font-size: 30px;
      line-height: 29px;
      text-align: center;
      font-family: Source Han Sans CN;
      font-weight: 400;
      color: #ffffff;
    }
    .nth::after {
      content: "";
      display: inline-block;
      width: 100px;
      height: 2px;
      margin-bottom: 10px;
      margin-left: 40px;
      background: cornsilk;
    }
    .nth::before {
      content: "";
      display: inline-block;
      width: 100px;
      margin-bottom: 10px;
      margin-right: 40px;
      height: 2px;
      background: cornsilk;
    }
  }
}
</style>

