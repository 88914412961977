<template>
  <div class="Technology">
    <div class="Technology_box">
      <div class="Technology_top">
        <p>技术扶持</p>
        <p>TECHNICAL SUPPORT</p>
      </div>
      <div class="Technology_bottun">
        <p>
          四川昆仑虚科技发展有限公司为实现技术与产业相结合的计划方针，仟禾计划将农村及地方商户的产品进行一系列的电商改革与升级，帮助商户摆脱地理位置、交通条件不便等对农产品销售的束缚，架起偏远地区农产品进入城市市场的“桥梁”，促进贫困地区产业发展，实现贫困户就业增收。
        </p>
        <img src="@/assets/imgs/qianhe/jsfc.png" alt="">
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped lang="less">
.Technology {
  width: 100%;
  margin-top: 60px;
  .Technology_box {
    max-width: 1200px;
    margin: 0 auto;
    .Technology_top {
      text-align: center;
      p:nth-child(1) {
        font-size: 30px;
        font-weight: unset;
        color: #000000;
        line-height: 70px;
      }
      p:nth-child(2) {
        font-size: 19px;
        font-family: unset;
        font-weight: unset;
        color: #000000;
        margin-bottom: 60px;
      }
    }
    .Technology_bottun {
      font-size: 20px;
      max-width: 720px;
      margin: 0 auto;
      font-family: unset;
      font-weight: 400;
      color: #221815;
      text-align:center;
      line-height: 50px;
    }
    img{
      max-width: 720px;
      margin-top: 70px;
    }
  }
}
</style>
