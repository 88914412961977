
<template>
  <div class="service">
    <div class="service_box">
      <div class="service_top">
        <p>孵化服务</p>
        <p>PROVIDE SERVICE</p>
      </div>
      <div class="service_bouutm">
        <div class="content_lsie">
          <div
            class="text_lefi"
            v-for="(item, index) in content"
            :key="index"
            @mouseover="active = index"
          >
            <p>{{ item.text }}</p>
            <i v-show="active == index" class="i"></i>
          </div>
        </div>
        <div
          class="service_content"
          v-for="(item, index) in content"
          :key="index"
          v-show="active == index"
        >
          <div v-for="(ites, index) in item.cont" :key="index">
            <div class="serv_img">
              <img :src="ites.img" alt="" />
            </div>
            <div class="serv_conte">
              <p>{{ ites.text }}</p>
              <p>{{ ites.texta }}</p>
              <p>{{ ites.textb }}</p>
              <p>{{ ites.textc }}</p>
              <p>{{ ites.textd }}</p>
              <p>{{ ites.texte }}</p>
              <p>{{ ites.textf }}</p>
              <p>
                {{ ites.textg }}（ <span>{{ ites.textga }}</span
                >）{{ ites.textl }}（<span>{{ ites.textgb }}</span
                >)。
              </p>
              <p>{{ ites.texth }}</p>
              <p>
                {{ ites.texti }}<span>{{ ites.textia }}</span
                >。
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      active: 0,
      content: [
        {
          text: "孵化基础版",
          cont: [
            {
              img: require("@/assets/imgs/qianhe/ms1.png"),
              text: "公共孵化内容：",
              texta: "1.根据商家产品类型匹配主播服务：短视频带货、直播带货",
              textb: "2.店铺装修服务，产品详情页设计，美工设计，产品上架",
              textc: "3.店铺后台数据跟踪，订单信息对接",
              textd: "4.产品销售分佣（需核算成本后另行签订协议）",
              texte: "5.针对商家电商技能线上培训",
              textf: "一、提供服务：",
              textg:
                "  企业logo、店招、礼品包装袋、手提袋设计、包装塑胶袋、产品内外包装。",
              textl: "、企业H5，原创视频剪辑",
              textga: "限3款",
              textgb: "10条",
              texth: "二、渠道搭建：",
              texti: "微信视频号+小商店开通、淘宝拼多多平台开通",
              textia: "二选一",
            },
          ],
        },
        {
          text: "孵化高级版",
          cont: [
            {
              img: require("@/assets/imgs/qianhe/ms.png"),
              text: "公共孵化内容：",
              texta: "1.根据商家产品类型匹配主播服务：短视频带货、直播带货",
              textb: "2.店铺装修服务，产品详情页设计，美工设计，产品上架",
              textc: "3.店铺后台数据跟踪，订单信息对接",
              textd: "4.产品销售分佣（需核算成本后另行签订协议）",
              texte: "5.针对商家电商技能线上培训",
              textf: " 一、服务",
              textg:
                " 企业logo、店招、礼品包装袋、手提袋设计、包装塑胶袋、产品内外包装",
              textl:
                "、企业名片、企业宣传册、企业H5、企业官网、企业小程序，原创视频剪辑",
              textga: "限6款",
              textgb: "20条",
              texth: "二、渠道搭建：",
              texti: "微信视频号+小商店、抖音、淘宝、拼多多",
              // textia: "二选一",
            },
          ],
        },
      ],
    };
  },
};
</script>
<style scoped lang="less">
.service {
  width: 100%;
  overflow: hidden;
  margin-top: 80px;
  margin-bottom: 140px;
  background: #fafafa;
  .service_box {
    max-width: 1200px;
    margin: 0 auto;
    .service_top {
      text-align: center;
      p:nth-child(1) {
        font-size: 30px;
        font-weight: unset;
        color: #000000;
        line-height: 70px;
        margin-top: 100px;
      }
      p:nth-child(2) {
        font-size: 19px;
        font-family: unset;
        font-weight: unset;
        color: #000000;
        margin-bottom: 50px;
      }
    }
    .service_bouutm {
      .content_lsie {
        max-width: 600px;
        height: 50px;
        position: relative;
        text-align: center;
        margin: 0 auto;
        .text_lefi {
          width: 160px;
          float: left;
          cursor: pointer;
          p {
            font-size: 25px;
            font-family: DengXian;
            font-weight: 400;
            color: #333333;
            letter-spacing: 2px;
            margin-top: 13px;
          }
          .i {
            display: inline-block;
            width: 160px;
            height: 2px;
            background: #224ede;
            margin-top: -10px;
          }
        }
        .text_lefi:nth-child(2) {
          float: right;
        }
      }
      .service_content {
        overflow: hidden;
        margin-top: 50px;
        margin-bottom: 130px;
        .serv_img {
          float: left;
          img {
            max-width: 525px;
          }
        }
        .serv_conte {
          width: 550px;
          float: right;
          margin-top: 70px;
          p {
            font-size: 19px;
            font-family: Source Han Sans CN;
            font-weight: 400;
            color: #333333;
            line-height: 36px;
            span {
              color: #cf0808;
            }
          }
        }
      }
    }
  }
}
</style>
