
<template>
  <div class="showoff">
    <div class="showoff_box">
      <div class="showoff_top">
        <p>案例展示</p>
        <p>CASE PRESENTATION</p>
      </div>
      <div class="showoff_bouutm">
        <div class="showoff_content">
          <div class="imga">
            <img src="@/assets/imgs/qianhe/yy.png" alt="" />
          </div>
          <p>粤与优品商城</p>
          <p>好货有好评，严选坚果，开店一年已是美食领域TOP级品牌</p>
        </div>
        <div class="showoff_content">
          <div class="imga">
            <img class="kl" src="@/assets/imgs/qianhe/jpq.png" alt="" />
          </div>
          <p>粤与优品商城</p>
          <p>好货有好评，严选坚果，开店一年已是美食领域TOP级品牌</p>
        </div>
        <div class="showoff_content">
          <div class="imga">
            <img class="aaa" src="@/assets/imgs/qianhe/lfq.png" alt="" />
          </div>
          <p>粤与优品商城</p>
          <p>好货有好评，严选坚果，开店一年已是美食领域TOP级品牌</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
};
</script>
<style scoped lang="less">
.showoff {
  width: 100%;
  overflow: hidden;
  .showoff_box {
    max-width: 1200px;
    margin: 0 auto;
    .showoff_top {
      text-align: center;

      p:nth-child(1) {
        font-size: 30px;
        font-weight: unset;
        color: #000000;
        line-height: 70px;
      }
      p:nth-child(2) {
        font-size: 19px;
        font-family: unset;
        font-weight: unset;
        color: #000000;
        margin-bottom: 50px;
      }
    }
  }
  .showoff_bouutm {
    height: 600px;
    .showoff_content {
      max-width: 373px;
      max-height: 496px;
      cursor: pointer;
      overflow: hidden;
      text-align: center;
      margin: 0 auto;
         box-shadow: 0px 0px 3px 6px rgba(223, 219, 219, 0.2);
      .imga {
        width: 202px;
        height: 202px;
        overflow: hidden;
        margin: 0 auto;
        margin-top: 60px;
        .kl,
        .aaa {
          margin-top: 50px;
        }
      }
      p:nth-child(2) {
        height: 26px;
        font-size: 27px;
        font-family: Source Han Sans CN;
        font-weight: 500;
        color: #333333;
        margin-top: 80px;
      }
      p:nth-child(3) {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: 400;
        margin-top: 35px;
        line-height: 27px;
        padding: 0 40px;
        color: #666666;
        margin-bottom: 40px;
      }
    }
  

    .showoff_content:nth-child(1) {
      float: left;
    }
    .showoff_content:nth-child(2) {
      float: right;
    }
  }
}
</style>
