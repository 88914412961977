<template>
  <div>
    <Brand />
      <Area/>
    <Technology/>
  <Strategic/>
  <Object/>
  <Service/>
  <Showoff/>
  </div>
</template>
<script>
import Brand from "@/components/pages/qianhe/Brand.vue";
import Technology from "@/components/pages/qianhe/Technology.vue";
import Area from "@/components/pages/qianhe/Area.vue";
import Strategic from "@/components/pages/qianhe/Strategic.vue";
import Object from "@/components/pages/qianhe/Object.vue";
import Service from "@/components/pages/qianhe/Service.vue";
import Showoff from "@/components/pages/qianhe/Showoff.vue";

export default {
  components: {
    Brand,
    Area,
   Technology,
   Strategic,
   Object,
   Service,
   Showoff
  },
  data() {
    return {};
  },
};
</script>
<style scoped>
</style>
